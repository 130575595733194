import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FooterComponent } from '@coreui/angular-pro';
import { AuthService } from 'src/services/user/auth.service';

@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
    imports: [
      NgIf
    ],
    standalone: true,
})
export class DefaultFooterComponent extends FooterComponent {
  userFullname: string = '';
  isfflifa: boolean = false;
  email: string = '';
  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    super();
  }

  ngOnInit(): void {
    this.userFullname = this.authService.getUserFullname();
    this.email = this.authService.getUserEmail();

    if(this.email.includes('feuerwehr-lilienthal')){
      this.isfflifa = true;
    }
  }
}
