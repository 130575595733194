import { DOCUMENT, NgIf, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, computed, Inject, inject, Input } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import {
	AvatarComponent,
	BadgeComponent,
	BreadcrumbRouterComponent,
	ButtonDirective,
	ColorModeService,
	ContainerComponent,
	DropdownComponent,
	DropdownDividerDirective,
	DropdownHeaderDirective,
	DropdownItemDirective,
	DropdownMenuDirective,
	DropdownToggleDirective,
	FormControlDirective,
	FormDirective,
	HeaderComponent,
	HeaderNavComponent,
	HeaderTogglerDirective,
	InputGroupComponent,
	InputGroupTextDirective,
	NavItemComponent,
	NavLinkDirective,
	ProgressBarDirective,
	ProgressComponent,
	SidebarToggleDirective,
	TextColorDirective,
	ThemeDirective
} from '@coreui/angular-pro';
import { IconDirective, IconModule, IconSetService } from '@coreui/icons-angular';
import {
	cilAccountLogout,
	cilAirplaneMode,
	cilBell,
	cilContrast,
	cilFullscreen,
	cilFullscreenExit,
	cilLanguage,
	cilMenu,
	cilMoon,
	cilPeople,
	cilSearch,
	cilSettings,
	cilSun,
	cilUser,
	cilVideo
} from '@coreui/icons-pro';
import { NotificationService } from 'src/services/shared/notification.service';
import { AuthService } from 'src/services/user/auth.service';
import { VideoMetaService } from 'src/services/video/videoMeta.service';

@Component({
	selector: 'app-default-header',
	templateUrl: './default-header.component.html',
	standalone: true,
	imports: [
		ContainerComponent,
		ReactiveFormsModule,
		HeaderTogglerDirective,
		SidebarToggleDirective,
		IconDirective,
		HeaderNavComponent,
		NavItemComponent,
		NavLinkDirective,
		RouterLink,
		RouterLinkActive,
		NgTemplateOutlet,
		BreadcrumbRouterComponent,
		ThemeDirective,
		DropdownComponent,
		DropdownToggleDirective,
		TextColorDirective,
		AvatarComponent,
		DropdownMenuDirective,
		DropdownHeaderDirective,
		DropdownItemDirective,
		BadgeComponent,
		DropdownDividerDirective,
		NgIf,
		ProgressBarDirective,
		ProgressComponent,
		InputGroupComponent,
		InputGroupTextDirective,
		FormControlDirective,
		ButtonDirective,
		NgStyle,
		FormDirective,
		IconModule
	],
	providers: [IconSetService]
})
export class DefaultHeaderComponent extends HeaderComponent {

	readonly #colorModeService = inject(ColorModeService);
	readonly colorMode = this.#colorModeService.colorMode;

	readonly colorModes = [
		{ name: 'light', text: 'Hell', icon: 'cilSun' },
		{ name: 'dark', text: 'Dunkel', icon: 'cilMoon' },
		{ name: 'auto', text: 'Auto', icon: 'cilContrast' }
	];

	readonly icons = computed(() => {
		const currentMode = this.colorMode();
		return this.colorModes.find(mode => mode.name === currentMode)?.icon ?? 'cilSun';
	});

	userFullname: string = '';
	pendingVideos: number = 0;
	newNotifications: Array<any> = [];

	constructor(
		@Inject(DOCUMENT) private document: any,
		private router: Router,
		public iconSet: IconSetService,
		private authService: AuthService,
		private videoMetaService: VideoMetaService,
		private notificationService: NotificationService
	) {
		super();
		this.#colorModeService.localStorageItemName.set('coreui-pro-angular-admin-template-theme-modern');
		this.#colorModeService.eventName.set('ColorSchemeChange');
		this.iconSet.icons = {
			cilAirplaneMode,
			cilPeople,
			cilLanguage,
			cilBell,
			cilMoon,
			cilMenu,
			cilVideo,
			cilSearch,
			cilFullscreen,
			cilFullscreenExit,
			cilSun,
			cilContrast,
			cilUser,
			cilSettings,
			cilAccountLogout
		};
	}

	@Input() sidebarId: string = 'sidebar1';

	elem: any;
	fullscreen: boolean = false;

	ngOnInit(): void {
		this.elem = document.documentElement;

		this.userFullname = this.authService.getUserFullname();

		this.loadPendingVideos();

		this.notificationService.pendingVideos$.subscribe(pendingCount => {
			if (pendingCount > 0) {
			  const message = pendingCount === 1 ? '1 Video wartet auf Freigabe' : `${pendingCount} Videos warten auf Freigabe`;
	  
			  // Update die Benachrichtigungen
			  const videoNotification = this.newNotifications.find(n => n.icon === 'cilVideo');
			  if (videoNotification) {
				videoNotification.title = message;
			  } else {
				this.newNotifications.unshift({
				  id: this.newNotifications.length,
				  title: message,
				  icon: 'cilVideo',
				  color: 'info',
				  link: '/videolist'
				});
			  }
			} else {
			  // Entferne die Benachrichtigung, wenn keine Videos mehr auf Freigabe warten
			  this.newNotifications = this.newNotifications.filter(n => n.icon !== 'cilVideo');
			}
		  });
	}

/* 	public newMessages = [
		{
			id: 0,
			from: 'Jessica Williams',
			avatar: '7.jpg',
			status: 'success',
			title: 'Urgent: System Maintenance Tonight',
			time: 'Just now',
			link: 'apps/email/inbox/message',
			message: 'Attention team, we\'ll be conducting critical system maintenance tonight from 10 PM to 2 AM. Plan accordingly...'
		},
		{
			id: 1,
			from: 'Richard Johnson',
			avatar: '6.jpg',
			status: 'warning',
			title: 'Project Update: Milestone Achieved',
			time: '5 minutes ago',
			link: 'apps/email/inbox/message',
			message: 'Kudos on hitting sales targets last quarter! Let\'s keep the momentum. New goals, new victories ahead...'
		},
		{
			id: 2,
			from: 'Angela Rodriguez',
			avatar: '5.jpg',
			status: 'danger',
			title: 'Social Media Campaign Launch',
			time: '1:52 PM',
			link: 'apps/email/inbox/message',
			message: 'Exciting news! Our new social media campaign goes live tomorrow. Brace yourselves for engagement...'
		},
		{
			id: 3,
			from: 'Jane Lewis',
			avatar: '4.jpg',
			status: 'info',
			title: 'Inventory Checkpoint',
			time: '4:03 AM',
			link: 'apps/email/inbox/message',
			message: 'Team, it\'s time for our monthly inventory check. Accurate counts ensure smooth operations. Let\'s nail it...'
		},
		{
			id: 4,
			from: 'Ryan Miller',
			avatar: '4.jpg',
			status: 'info',
			title: 'Customer Feedback Results',
			time: '3 days ago',
			link: 'apps/email/inbox/message',
			message: 'Our latest customer feedback is in. Let\'s analyze and discuss improvements for an even better service...'
		}
	];

	public newNotifications = [
		{ id: 0, title: 'New user registered', icon: 'cilUserFollow', color: 'success' },
		{ id: 1, title: 'User deleted', icon: 'cilUserUnfollow', color: 'danger' },
		{ id: 2, title: 'Sales report is ready', icon: 'cilChartPie', color: 'info' },
		{ id: 3, title: 'New client', icon: 'cilBasket', color: 'primary' },
		{ id: 4, title: 'Server overloaded', icon: 'cilSpeedometer', color: 'warning' }
	];

	public newStatus = [
		{ id: 0, title: 'CPU Usage', value: 25, color: 'info', details: '348 Processes. 1/4 Cores.' },
		{ id: 1, title: 'Memory Usage', value: 70, color: 'warning', details: '11444GB/16384MB' },
		{ id: 2, title: 'SSD 1 Usage', value: 90, color: 'danger', details: '243GB/256GB' }
	];

	public newTasks = [
		{ id: 0, title: 'Upgrade NPM', value: 0, color: 'info' },
		{ id: 1, title: 'ReactJS Version', value: 25, color: 'danger' },
		{ id: 2, title: 'VueJS Version', value: 50, color: 'warning' },
		{ id: 3, title: 'Add new layouts', value: 75, color: 'info' },
		{ id: 4, title: 'Angular Version', value: 100, color: 'success' }
	]; */

	logout() {
		localStorage.removeItem('token');
		this.router.navigate(['/login']);
	}

	profile() {
		this.router.navigate(['/profile']);
	}

	loadPendingVideos(): void {
		this.videoMetaService.getPendingVideos().subscribe(data => {
		  this.pendingVideos = data;
		  
		  // Dynamisch das Wording für pending Videos anpassen
		  if (this.pendingVideos > 0) {
			const message = this.pendingVideos === 1 
			  ? '1 Video wartet auf Freigabe' 
			  : `${this.pendingVideos} Videos warten auf Freigabe`;
			
			// Füge die Benachrichtigung zur Liste der Benachrichtigungen hinzu
			this.newNotifications.unshift({
			  id: this.newNotifications.length, // Generiere eine ID basierend auf der Länge
			  title: message,
			  icon: 'cilVideo',
			  color: 'info',
			  link: '/videolist'  // Füge den Link zur Video-Liste hinzu
			});
		  }
		});
	  }
	  

	toggleFullscreen() {
		if (this.fullscreen) {
			if (this.document.exitFullscreen) {
				this.document.exitFullscreen();
			} else if (this.document.mozCancelFullScreen) {
				/* Firefox */
				this.document.mozCancelFullScreen();
			} else if (this.document.webkitExitFullscreen) {
				/* Chrome, Safari and Opera */
				this.document.webkitExitFullscreen();
			} else if (this.document.msExitFullscreen) {
				/* IE/Edge */
				this.document.msExitFullscreen();
			}

			this.fullscreen = false;
		} else {
			if (this.elem.requestFullscreen) {
				this.elem.requestFullscreen();
			} else if (this.elem.mozRequestFullScreen) {
				/* Firefox */
				this.elem.mozRequestFullScreen();
			} else if (this.elem.webkitRequestFullscreen) {
				/* Chrome, Safari and Opera */
				this.elem.webkitRequestFullscreen();
			} else if (this.elem.msRequestFullscreen) {
				/* IE/Edge */
				this.elem.msRequestFullscreen();
			}

			this.fullscreen = true;
		}
	}
}
