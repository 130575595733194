import { Component, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';
import { AuthService } from 'src/services/user/auth.service';
import { IconModule, IconSetService } from '@coreui/icons-angular';
import { cibSkype, cilAirplaneMode, cilBook, cilBookOpen, cilBuilding, cilCalendar, cilCamera, cilCommentBubbleQuestion, cilDrop, cilFlightTakeoff, cilHome, cilList, cilLocationPin, cilLockLocked, cilMap, cilMenu, cilPeople, cilPlaylistPlay, cilSearch, cilSettings, cilSpeech, cilSun, cilTvLive, cilUser} from '@coreui/icons-pro';

@Component({
	selector: 'app-root',
	template: '<router-outlet />',
	standalone: true,
	imports: [
		RouterOutlet,
		IconModule
	],
	providers: [
		IconSetService
	]
})
export class AppComponent implements OnInit {
	title = 'Drohnenportal';

	constructor(
		private router: Router,
		private titleService: Title,
		private iconSet: IconSetService,
		private authService: AuthService
	) {
		this.titleService.setTitle(this.title);
		// iconSet singleton
		this.iconSet.icons = {
			cilSun, cilDrop, cilAirplaneMode, cilFlightTakeoff, cilTvLive, cilBook, cilPlaylistPlay, cilPeople, cilBookOpen, cilUser, cilLockLocked, cilBuilding, cilCamera, cilCalendar, cilLocationPin, cibSkype, cilHome, cilList, cilSpeech, cilSettings, cilMenu, cilSearch, cilCommentBubbleQuestion, cilMap
		};
	}

	ngOnInit(): void {
		this.router.events.pipe(
			filter((event) => event instanceof NavigationStart)
		).subscribe((event) => {
			let helper = this.authService.isUserTokenExpired();
			if (helper && !this.router.url.includes('login') && this.router.url != '/') {
				window.location.href = '/login';
			}
		});
	}
}
