import { INavDataExtender } from '../../extender/nav.extender'; // Der neue Typ wird importiert

export const navItems: INavDataExtender[] = [
	{
		title: true,
		name: 'Wetterinformationen',
		requiredPermission: 'canViewWeather'
	},
	{
		name: 'Wetter',
		iconComponent: { name: 'cil-sun' },
		url: '/weather',
		requiredPermission: 'canViewWeather'
	},
	{
		title: true,
		name: 'Gewässerinformationen',
		requiredPermission: 'canViewWaterLevel'
	},
	{
		name: 'Pegel',
		iconComponent: { name: 'cil-drop' },
		url: '/waterlevels',
		requiredPermission: 'canViewWaterLevel'
	},
	{
		title: true,
		name: 'Videos',
		requiredPermission: ['canViewVideos', 'canUploadVideos']
	},
	{
		name: 'Karte',
		iconComponent: { name: 'cilAirplaneMode' },
		url: '/videomap',
		requiredPermission: ['canViewVideos', 'canUploadVideos']
	},
	{
		name: 'Live',
		iconComponent: { name: 'cilTvLive' },
		url: '/live-map',
		requiredPermission: ['canViewFlightDeviceLivestreams']
	},
	{
		name: 'Verwaltung',
		iconComponent: { name: 'cilPlaylistPlay' },
		url: '/videolist',
		requiredPermission: 'isVideoAdmin'
	},
	{
		name: 'Fluggeräte',
		title: true,
		requiredPermission: ['isDroneAdmin']
	},
	{
		name: 'Drohnen',
		url: '/flight-devices',
		iconComponent: { name: 'cilFlightTakeoff' },
		requiredPermission: ['isDroneAdmin']
	},
	{
		name: 'Dokumentation',
		title: true,
		requiredPermission: ['isAuthorityAdmin']
	},
	{
		name: 'Flugbuch',
		url: '/flight-log',
		badge: {
			text: 'Demnächst',
			color: 'success'
		},
		iconComponent: { name: 'cilBook' },
		requiredPermission: ['isAuthorityAdmin']
	},
	{
		name: 'Konfiguration',
		title: true,
		requiredPermission: ['isUserAdmin', 'canEditVideoCategories']
	},
	{
		name: 'Benutzerverwaltung',
		url: '/user-management',
		iconComponent: { name: 'cilPeople' },
		requiredPermission: ['isUserAdmin']
	},
	{
		name: 'Logbuch',
		url: '/logbook',
		badge: {
			text: 'Demnächst',
			color: 'success'
		},
		iconComponent: { name: 'cilBookOpen' },
		requiredPermission: ['isAuthorityAdmin']
	},
	{
		name: 'Organisationsverwaltung',
		url: '/authority-management',
		iconComponent: { name: 'cilBuilding' },
		requiredPermission: 'isAuthorityAdmin'
	},
	{
		name: 'Videoverwaltung',
		url: '/video-management',
		iconComponent: { name: 'cilCamera' },
		requiredPermission: 'canEditVideoCategories'
	},
];
