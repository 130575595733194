import { Routes } from '@angular/router';
import { DefaultLayoutComponent } from '../layout/default-layout';
import { authGuard } from '../guards/auth.guard';
import { permissionGuard } from '../guards/permission.guard';

export const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: '#',
		redirectTo: '/login',
		pathMatch: 'full'
	},
	{
		path: '404',
		loadComponent: () => import('./page404/page404.component').then(m => m.Page404Component),
		data: {
			title: 'Page 404'
		}
	},
	{
		path: '500',
		loadComponent: () => import('./page500/page500.component').then(m => m.Page500Component),
		data: {
			title: 'Page 500'
		}
	},
	{
		path: 'login',
		loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
		data: {
			title: 'Login Page'
		}
	},
	{
		path: 'register',
		loadComponent: () => import('./register/register.component').then(m => m.RegisterComponent),
		data: {
			title: 'Register Page'
		}
	},
	{
		path: '',
		component: DefaultLayoutComponent,
		data: {
			title: 'Home'
		},
		canActivate: [authGuard],
		children: [
			{
				path: 'dashboard',
				loadChildren: () => import('./dashboard/routes').then(m => m.routes),
				canActivate: [authGuard]
			},
			{
				path: 'weather',
				loadComponent: () => import('./weather/weather.component').then(m => m.WeatherComponent),
				canActivate: [authGuard]
			},
			{
				path: 'waterlevels',
				loadComponent: () => import('./waterlevels/waterlevels.component').then(m => m.WaterLevelsComponent),
				canActivate: [authGuard]
			},
			{
				path: 'flight-devices',
				loadComponent: () => import('./flight-devices/flight-devices.component').then(m => m.FlightDevicesComponent),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: ['isDroneAdmin'] }
			},
			{
				path: 'flight-log',
				loadComponent: () => import('./flight-log/flight-log.component').then(m => m.FlightLogComponent),
				canActivate: [authGuard]
			},
			{
				path: 'videomap',
				loadChildren: () => import('./map/routes').then(m => m.routes),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: ['canViewVideos', 'canUploadVideos'] }
			},
			{
				path: 'videolist',
				loadComponent: () => import('./videolist/videolist.component').then(m => m.VideolistComponent),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: 'canViewVideos' }
			},
			{
				path: 'live-map',
				loadComponent: () => import('./live-map/live-map.component').then(m => m.LiveMapComponent),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: ['canViewFlightDeviceLivestreams'] }
			},
			{
				path: 'user-management',
				loadComponent: () => import('./settings/user-management/user-management.component').then(m => m.UserManagementComponent),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: ['isUserAdmin'] }
			},
			{
				path: 'logbook',
				loadComponent: () => import('./logbook/logbook.component').then(m => m.LogbookComponent),
				canActivate: [authGuard]
			},
			{
				path: 'authority-management',
				loadComponent: () => import('./settings/authority-management/authority-management.component').then(m => m.AuthorityManagementComponent),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: 'isAuthorityAdmin' }
			},
			{
				path: 'video-management',
				loadComponent: () => import('./settings/video-management/video-management.component').then(m => m.VideoManagementComponent),
				canActivate: [authGuard, permissionGuard],
				data: { requiredPermission: 'canEditVideoCategories' }
			},
			{
				path: 'profile',
				loadComponent: () => import('./user-profile/user-profile.component').then(m => m.UserProfileComponent),
				canActivate: [authGuard],
			},
			{
				path: 'changelog',
				loadComponent: () => import('./changelog/changelog.component').then(m => m.ChangelogComponent),
				canActivate: [authGuard]
			}
		]
	},
	{
		path: '**',
		redirectTo: '/login',
		pathMatch: 'full'
	}
];
