import { Component } from '@angular/core';
import { RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';

import {
  ButtonCloseDirective,
  ContainerComponent,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective
} from '@coreui/angular-pro';

import { INavDataExtender as INavData } from '../../extender/nav.extender';

import { DefaultAsideComponent, DefaultBreadcrumbComponent, DefaultFooterComponent, DefaultHeaderComponent } from './';
import { navItems as allNavItems } from './_nav';
import { userRightsService } from '../../../services/user/userRights.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    RouterLink,
    NgScrollbar,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    DefaultAsideComponent,
    DefaultHeaderComponent,
    ShadowOnScrollDirective,
    ContainerComponent,
    RouterOutlet,
    DefaultFooterComponent,
    ButtonCloseDirective,
    DefaultBreadcrumbComponent,
  ],
})
export class DefaultLayoutComponent {

  public navItems: INavData[];

  constructor(
    private authService: userRightsService,
  ) {
    this.navItems = this.filterNavItemsByPermission(allNavItems);
  }

  filterNavItemsByPermission(navItems: INavData[]): INavData[] {
    return navItems.filter(item => {
      if (!item.requiredPermission) {
        return true; // Element hat keine Berechtigung, also anzeigen
      }
  
      // Prüfen, ob `requiredPermission` eine Liste ist
      if (Array.isArray(item.requiredPermission)) {
        // Rückgabe true, wenn der Benutzer mindestens eines der Rechte hat
        return item.requiredPermission.some(permission => this.authService.hasRight(permission));
      }
  
      // Einzelnes Recht prüfen
      return this.authService.hasRight(item.requiredPermission);
    });
  }
  
}
